import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Convergence is a technique that `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` use to peform `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` by converging their belief into a single point, allowing them to create a strong `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      